export const WEDDING_INVITATION_URL = "http://localhost:3000/";
export const KAKAOTALK_SHARE_IMAGE =
  "https://cdn.pixabay.com/photo/2014/11/13/17/04/heart-529607_960_720.jpg";

export const ADDRESS = '충북 진천군 용석로 184'

export const GROOM_NAME = "신랑 안성균";
export const GROOM_ACCOUNT_NUMBER = "우리은행 1002-649-940580";
export const GROOM_KAKAOPAY = 'https://qr.kakaopay.com/FDqYjQzu8';
export const GROOM_FATHER_NAME = "아버지 안경훈";
export const GROOM_FATHER_ACCOUNT_NUMBER = "농협은행 351-103685-6213";
export const GROOM_FATHER_KAKAOPAY = "";
export const GROOM_MOTHER_NAME = "";
export const GROOM_MOTHER_ACCOUNT_NUMBER = "○○은행 ***-***-******";
export const GROOM_MOTHER_KAKAOPAY = "";

export const BRIDE_NAME = "신부 김주은";
export const BRIDE_ACCOUNT_NUMBER = "카카오뱅크 3333223141761";
export const BRIDE_KAKAOPAY = "https://qr.kakaopay.com/Ej75OCZJG";
export const BRIDE_FATHER_NAME = "아버지 김 인";
export const BRIDE_FATHER_ACCOUNT_NUMBER = "기업은행 01083839020";
export const BRIDE_FATHER_KAKAOPAY = "";
export const BRIDE_MOTHER_NAME = "";
export const BRIDE_MOTHER_ACCOUNT_NUMBER = "";
export const BRIDE_MOTHER_KAKAOPAY = "";